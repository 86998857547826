import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/welfare.png'

const LabourWelfareAct = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>Labour Welfare Fund Act, 1965</h2>
                                        <p>
                                            Recognizing that a nurtured workforce is fundamental to a thriving organization, our holistic services under the Labor Welfare Fund Act, 1965, are crafted to prioritize the welfare of your employees.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2'>
                                    <h3>
                                        Labor Welfare Fund Act, 1965: Improving Employee Welfare
                                    </h3>
                                    <p>
                                        At Manpower Company, we recognize that a well-supported workforce is crucial for the success of any organization. Our extensive services under the Labor Welfare Fund Act, 1965, are tailored to prioritize the welfare of your employees, in line with the regulatory requirements of this important legislation.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2'>
                                    <h5>Our Labour Welfare Fund Act Services</h5>
                                    <ol>
                                        <li><b>Guidance and Compliance:</b> Our team of professionals is well-versed in the intricacies of the Labor Welfare Fund Act, 1965. We offer expert guidance to assist you in understanding and implementing the Act's provisions, ensuring complete compliance and fostering a positive workplace environment.</li>
                                        <li><b>Documentation Support:</b> Maneuvering through the documentation requirements of the Act can be intricate. We provide assistance in maintaining precise records, submitting required forms, and ensuring adherence to reporting obligations.</li>
                                        <li><b>Employee Benefits Management:</b> The Act mandates welfare measures for employees. Our services encompass aiding you in crafting and managing welfare initiatives that elevate the standard of living for your workforce, thereby enhancing employee satisfaction and retention.</li>
                                        <li><b>Customized Solutions:</b> We recognize that each organization has distinct workforce dynamics. Our solutions are customized to fit your industry, company scale, and employee requirements, ensuring that your welfare endeavors are impactful and tailored.</li>
                                        <li><b>Audit and Evaluation:</b> Our thorough audits evaluate your current practices vis-à-vis the requirements of the Labor Welfare Fund Act. This assists in pinpointing areas that necessitate attention and provides recommendations to bolster your compliance endeavors.</li>
                                    </ol>

                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2'>
                                    <h5>Reasons to choose us:</h5>
                                    <ol>
                                        <ol>
                                            <li><b>Unique Expertise:</b> Our specialized knowledge sets us apart. We possess in-depth understanding of the Labor Welfare Fund Act, 1965, ensuring comprehensive compliance solutions.</li>
                                            <li><b>Efficient Documentation:</b> We streamline the documentation process. Our assistance ensures accurate record-keeping and seamless submission of necessary forms.</li>
                                            <li><b>Employee Welfare Focus:</b> Our priority is your employees' well-being. We facilitate the development and management of welfare programs to enhance their quality of life.</li>
                                            <li><b>Tailored Solutions:</b> We cater to your specific needs. Our solutions are customized to your industry, company size, and workforce dynamics for maximum effectiveness.</li>
                                            <li><b>Thorough Assessment:</b> We conduct rigorous audits. Our evaluations identify areas for improvement and provide actionable recommendations to enhance compliance efforts.</li>
                                        </ol>
                                    </ol>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div class='content-container2'>
                                    <div class='sub-heading'>
                                        Contact Us Now:
                                    </div>
                                    <p>
                                        Take your company's dedication to employee welfare and compliance to new heights with the Labor Welfare Fund Act, 1965 services provided by Manpower Company. Contact us today to explore how we can assist you in establishing a flourishing workplace culture that prioritizes and nurtures its workforce.
                                    </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default LabourWelfareAct