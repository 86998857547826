import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/epf&mp.png'
import { NavLink } from 'react-router-dom'

const EPFandMPact = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section' data-aos='fade-left'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>EPF & MP Act Regulatory Assistance</h2>
                                        <p>
                                            Ensuring compliance with the Employees' Provident Funds & Miscellaneous Provisions Act, 1952 and the Employees' Provident Funds Scheme, 1952.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2' data-aos='zoom-in-down'>
                                    <h3>
                                        Experience seamless compliance services for EPF & MP Act by Manpower Company
                                    </h3>
                                    <p>
                                        The Employee Provident Fund (EPF) scheme, introduced by the government, aims to safeguard employees' welfare and provide social security. Under the Employees' Provident Funds & Miscellaneous Provisions Act, 1952, EPF Registration is compulsory for all employees.
                                    </p>
                                    <p>
                                        EPF Registration is mandatory for each employee hired by a company, and regular monthly contributions must be made to their PF accounts. Manpower Company offers comprehensive solutions for EPF Online Registration, enabling business owners to focus on core operations instead of dedicating extensive time to EPF management.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>1. Initial Setup and Registration:</h5>
                                    <ul>
                                        <li>Assistance with obtaining the Universal Account Number (UAN) for the employer.</li>
                                        <li>Registration of the organization with the EPF Organization (EPFO) through the completion and submission of necessary forms and documents.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>2. Employee Enrollment:</h5>
                                    <ul>
                                        <li>Gathering and verifying employee details necessary for EPF registration</li>
                                        <li>Assisting employees in generating their individual UANs.</li>
                                        <li>Linking employee KYC details with their EPF accounts for seamless transactions and verifications.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>3. Documentation and Compliance:</h5>
                                    <ul>
                                        <li>Ensuring all required documentation is accurately filled out and submitted. This includes details of employees, salaries, and other mandatory disclosures.</li>
                                        <li>Advising on compliance with the EPF & MP Act, including updates to legislation or changes in procedural requirements.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>4. Monthly Contributions:</h5>
                                    <ul>
                                        <li>Calculating the monthly EPF contributions for both employer and employees based on the latest EPF rules and salary structures.</li>
                                        <li>Facilitating the timely and correct deposit of EPF contributions to the respective EPF accounts.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>5. Claims and Withdrawals:</h5>
                                    <ul>
                                        <li>Assisting employees with the withdrawal process, whether partial or full, under permissible circumstances as specified by the EPFO</li>
                                        <li>Managing claims for advance from EPF accounts for reasons like medical emergencies, home loans, education, etc.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>5. Online Account Management:</h5>
                                    <ul>
                                        <li>Providing technology solutions for tracking EPF contributions, account status, and history.</li>
                                        <li>Ensuring employees can access their EPF accounts online to check balances, download passbooks, and track claim status.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>6. Regular Updates and Notifications:</h5>
                                    <ul>
                                        <li>Keeping the company and its employees informed about any changes in EPF policies, interest rates, and other relevant updates.</li>
                                        <li>Providing regular notifications concerning deadlines for monthly contributions and annual account statements.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>7. Audit and Record Maintenance:</h5>
                                    <ul>
                                        <li>Maintaining records of all EPF transactions and documents as required by law.</li>
                                        <li>Assisting with audits by the EPF authorities, ensuring all records are readily available and compliant with the regulations.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>8. Employee Education and Support:</h5>
                                    <ul>
                                        <li>Conducting sessions to educate employees about the benefits and features of EPF.</li>
                                        <li>Setting up helpdesks or support channels to address employee queries regarding their EPF accounts and related issues.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>9. Annual Statements and Reports:</h5>
                                    <ul>
                                        <li>Generating and distributing annual statements to employees showing the details of their contributions, accumulated amount, and interest credited.</li>
                                        <li>Preparing reports for the employer on the overall status and analytics of EPF contributions for financial and planning purposes.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default EPFandMPact