export const navData = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'Services',
        path: 'services',
        subItem: [
            {
                label: 'Manpower Services',
                path: 'manpowerservices'
            },
            {
                label: 'Outsourcing/ Payroll ',
                path: 'payrollprocessing'
            },
            {
                label: 'EPF & MP Act',
                path: 'epfandmpact',

            },
            {
                label: 'ESI Act',
                path: 'esiact'
            },
            {
                label: 'Shop & Establishment',
                path: 'shopandestablishment',
                // children: [
                //     {
                //         label: 'Labour Welfare Fund Act, 1965',
                //         path: 'labourwelfarefundact'
                //     },
                //     {
                //         label: 'Minimum Wages Act, 1948',
                //         path: 'minimumwagesact'
                //     },
                //     {
                //         label: 'Contract Labour Act, 1970',
                //         path: 'contractlabouract'
                //     },
                //     {
                //         label: 'Payment Gratuity Act, 1972',
                //         path: 'paymentgratuityact'
                //     },
                // ]
            },
            {
                label: 'Factories Act',
                path: 'factoryact'
            },
            {
                label: 'Staffing Solutions',
                path: 'staffingsolutions'
            }
        ]
    },
    {
        label: 'About Us',
        path: 'about-us'
    },
    {
        label: 'Contact Us',
        path: 'contact-us'
    }
]