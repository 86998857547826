import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/contract2.png'

const ContractLabourAct = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>Contract Labour Act, 1970</h2>
                                        <p>
                                            The Payment of Bonus Act, 1965, stipulates that employees should receive a portion of the organization's profits as a bonus. At Manpower Company, we provide a variety of services customized to support your organization in complying with this legislation and ensuring fair distribution of bonuses to employees.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2'>
                                    <h3>

                                        Expert Payment of Bonus Act, 1965 Services by <span> Manpower Company</span>
                                    </h3>
                                    <p>
                                        Guaranteeing adherence to the Employees' State Insurance (ESI) Act stands as a crucial obligation for enterprises nationwide. At Manpower Company, we acknowledge the paramount importance of ESI compliance and present an all-encompassing array of solutions meticulously crafted to aid you in smoothly maneuvering through the complexities of the ESI Act. Our proficient team is committed to streamlining ESI compliance, empowering you to concentrate on your fundamental business undertakings with certainty.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2'>
                                    <h5>The Contract Labour (Regulation and Abolition) Act, 1970</h5>
                                    <p>
                                        The Contract Labour Act aims to ensure equal rights and regulations for contractual workers. Typically, the working conditions and regulations for contract laborers differ from those of direct employees. Their employment relationship and payment methods also vary, as they are not directly employed by the company. Instead, they are hired, managed, and paid by contractors.
                                    </p>
                                    <p>
                                        As a business owner who employs contractors and contract workers, it is essential to adhere to the guidelines and regulations outlined in the Contract Labour Act.
                                    </p>
                                    <p>
                                        With Manpower Company, you can focus entirely on the core operations of your business rather than grappling with the intricacies of various laws and regulations. Our team of highly experienced professionals offers comprehensive assistance with all legal matters and payroll processing for your company.
                                    </p>
                                    <p>
                                        With over 34 years of industry experience, Manpower Company has emerged as a leading service provider in Delhi, NCR, and across India. Our expert staff provides consultancy services to ensure seamless compliance with the Contract Labour (R&A) Act, 1970, facilitating hassle-free business operations. We also handle the creation, maintenance, and submission of all necessary documents, registers, and forms in accordance with the policies outlined in the Contract Labour (Regulation & Abolition) Act, 1970, and Contract Labour (Regulation & Abolition) Central Rules, 1971.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2'>
                                    <h5>Maintenance of the Following Records, Statutory Obligations, and Submissions under the Contract Labour (Regulation and Abolition) Act</h5>
                                    <ul>
                                        <li>Registration of establishment and acquisition of certificate under Section 7 of the legislation.</li>
                                        <li>Contractor register in Form XII.</li>
                                        <li>Contractor register in Form XII.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div class='content-container2'>
                                    <h5>Management of the Following Documentation and Submissions According to the Contract Labour (Regulation and Abolition) Act for Contractors</h5>
                                    <ul>
                                        <li>Contractor's license under CLRA.</li>
                                        <li>Register of contract laborers in Form XIII.</li>
                                        <li>Employee identification card in Form No. XIV.</li>
                                        <li>Muster Roll and Wage Register in Form XVI and Form XVII, respectively.</li>
                                        <li>Register of deductions for damages or losses, fines, and advances in Form No. XX, Form No. XXI, and Form No. XXII, respectively.</li>
                                        <li>Overtime register in Form No. XXIII.</li>
                                        <li>Wage slips in Form No. XIX.</li>
                                        <li>Display of abstract of the act and rules in English and Hindi.</li>
                                        <li>Display of notice indicating wage rates, working hours, wage payment period, payment dates, and inspector's name and address in English and Hindi.</li>
                                        <li>Notice of commencement/completion of contract work in Form No. 6 (B).</li>
                                        <li>Half-yearly return in Form No. 24.</li>
                                    </ul>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default ContractLabourAct