import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/shop.png'
import { NavLink } from 'react-router-dom'

const ShopAndEstablishment = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section' data-aos='fade-left'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container' data-aos='zoom-in-down'>
                                        <h2>Shop & Establishment Act Compliances</h2>
                                        <p>
                                            Welcome to Manpower Company, your reliable ally for Shop & Establishment Act Compliance Services in India. We grasp the vital significance of adhering to the legal framework that regulates the operations of commercial establishments.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h3>
                                        Ensure compliance with Shop & Establishment Act regulations with Manpower Company
                                    </h3>
                                    <p>At Manpower Company, we provide specialized services to aid businesses throughout India in adhering to the Shop & Establishment Act. Our comprehensive knowledge of this legislation allows us to deliver customized solutions, ensuring your fulfillment of legal requirements while promoting a conducive work environment.</p>
                                    <p>Enhance your business's compliance with the Shop & Establishment Act by teaming up with Manpower Company. Contact us today to discover how we can assist you on your compliance path and contribute to the success of your enterprise.</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Shop & Establishment ACT:</h5>
                                    <p>
                                        The Shop and Establishment Act is a state-specific legislation in India that governs the working conditions, rights, and obligations of employees and employers in commercial establishments. The primary objective of this act is to regulate various aspects of employment, such as working hours, wages, leaves, holidays, and other employment-related conditions, to ensure the well-being of employees and to maintain the overall functioning of commercial establishments.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Basic Rules of the Shop and Establishment Act</h5>
                                    <ul>
                                        <li>Registration of all types of businesses is obligatory.</li>
                                        <li>Notification of business closure is required.</li>
                                        <li>Adherence to regulations concerning employee operations and working conditions is essential.</li>
                                        <li>Mandatory maintenance and display of registers and records are necessary.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>How will Manpower Company Help?</h5>
                                    <p>
                                        The dedicated team at Manpower Company offers comprehensive services and consultancy related to the Shop and Establishment Act for businesses. They deliver a diverse array of services, including: -
                                    </p>
                                    <ul>
                                        <li>License registration and renewal.</li>
                                        <li>Compilation and upkeep of various files and records as mandated by the regulations.</li>
                                        <li>Effective communication with the Labour Commissioner regarding business operations.</li>
                                        <li>Regular provision of updates and guidance on labor laws and any relevant amendments to the business.</li>
                                    </ul>
                                    <p>
                                        Manpower Company boasts a team of highly experienced and professional staff, complemented by state-of-the-art infrastructure and an up-to-date operating system, ensuring top-notch service and consultancy across Delhi and the NCR region.
                                    </p>
                                    <ul>
                                        <li>Processing and renewing licenses.</li>
                                        <li>Maintaining a record of fines using Form -I [Rule 21(4)].</li>
                                        <li>Keeping track of deductions with Form - II [Rule 21(4)].</li>
                                        <li>Submitting the annual return using Form -III [Rule 21(4)].</li>
                                        <li>Recording overtime hours in Form -IV [Rule 25(2)].</li>
                                        <li>Handling communication with the office of the "Labour Commissioner".</li>
                                        <li>Providing guidance on labor laws.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default ShopAndEstablishment