import React from 'react'
import '../../css/payRollProcessing.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/Payroll.png'
import { NavLink } from 'react-router-dom'
import HeaderTitle from '../../components/headerTitle/HeaderTitle'

const PayRollProcessing = () => {
    return (
        <>
            {/* <HeaderTitle title={'Payroll Processing'} /> */}
            <section>
                <div className='payrollProcessing' >
                    <div className='top-section' data-aos='fade-left'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={4}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={8}>
                                    <div className='content-container'>
                                        <h2>Manpower Outsourcing/ Payroll Management</h2>
                                        <p>
                                            Opting for our Manpower Outsourcing and Payroll Management services ensures your business can scale labour resources efficiently and responsively, minimizing HR overheads while maximizing compliance and operational effectiveness. We leverage expert knowledge and advanced technology to streamline your HR processes, enabling you to focus on core business activities without the complexities of workforce management. This approach not only saves costs but also enhances the flexibility and strategic allocation of your business resources.
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2' data-aos='zoom-in-down'>
                                    <h3>
                                        <span>Manpower Company - </span> Your reliable choice for outsourced payroll solutions.
                                    </h3>
                                    <ul>
                                        <li>
                                            <p><b>Payroll Management:</b> Streamlines payroll processing, ensuring accuracy, timeliness, and compliance with tax and employment laws.</p>
                                        </li>
                                        <li><p><b>Comprehensive Staffing Solutions:</b> Provides skilled, semi-skilled, and unskilled staff tailored to industry needs, ensuring operational efficiency.</p>
                                        </li>
                                        <li><p><b>Regulatory Compliance:</b> Manages all legal aspects of employment, from tax regulations to compliance with labor laws, reducing legal risks.</p>
                                        </li>
                                        <li><p><b>Focus on Core Business:</b> Allows companies to concentrate on core business activities by handling all HR-related tasks.</p>
                                        </li>
                                        <li><p><b>Flexibility and Scalability:</b> Offers adjustable staffing options to meet fluctuating business demands. Easily scale your workforce up or down based on business needs without the long-term commitment and expense of permanent hires.</p></li>
                                        <li><p><b>Cost Reduction:</b> Outsourcing helps minimize the costs associated with recruitment, training, and maintaining employee infrastructure.</p></li>
                                        <li><p><b>Access to Expertise:</b> Provides expert HR and payroll services delivered by experienced professionals.</p></li>
                                        <li><p><b>Risk Management:</b> Reduce the business and legal risks associated with employee management, payroll discrepancies, and compliance issues.</p></li>
                                        <li><p><b>Improved HR Strategies:</b> Benefit from the best practices and advanced strategies developed by experts focused solely on HR and payroll management.</p></li>
                                        <li><p><b>Quality Assurance:</b> Outsourcing companies often have higher standards and more rigorous checks and balances, leading to higher quality outputs and services.</p>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default PayRollProcessing