
import React, { useState, useEffect } from 'react';
import './scrollToTopButton.css';
import { FaArrowUp } from "react-icons/fa";
import { useLocation } from 'react-router-dom';

const ScrollToTopOnNavigate = () => {
    const [showButton, setShowButton] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 200) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    const scroll = () => {
        window.scrollTo(0, 0);
    }
    return (
        <>
            {showButton && (
                <div className='scrolltoTop' onClick={scroll} data-aos='fade-down'>
                    <FaArrowUp />
                </div>
            )}
        </>
    );
}

export default ScrollToTopOnNavigate;
