import React from 'react'
import './footer.css'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { FaWhatsapp, FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { HiBuildingOffice } from "react-icons/hi2";
import { IoIosCall, IoMdMail } from "react-icons/io";
const Footer = () => {
    return (
        <section data-aos='zoom-in-up'>
            <div className='footer'>
                <Container>
                    <Row className="padds p-0">
                        <Col xs={12} md={4}>
                            <div className='details'>
                                <h2>Manpower Company</h2>
                                <p>
                                    Manpower Company India, headquartered in Delhi, offers specialized  Manpower & payroll outsourcing services. We cater to startups, small businesses, and mid-size companies nationwide with tailored payroll processing solutions. Trust us to handle your payroll needs efficiently, freeing you to focus on core business activities.
                                </p>
                                <div className='social-links-container'>
                                    <NavLink className='social-links' target='_blank' to='https://www.facebook.com/profile.php?id=100089977908470 '><FaFacebookF /> </NavLink>
                                    <NavLink className='social-links' target='_blank' to='https://www.linkedin.com/in/ab-square-facility-management-services-pvt-ltd-585611a8/'><FaLinkedinIn /> </NavLink>
                                    <NavLink className='social-links' target='_blank' to='https://wa.me/918588837004'><FaWhatsapp /> </NavLink>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                            <div className='our-links'>
                                <h2>Our Services </h2>
                                <div>
                                    <NavLink to='Manpowerservices' className='links'>Manpower Services</NavLink>
                                </div>
                                <div>
                                    <NavLink to='payrollprocessing' className='links'>Outsourcing/ Payroll </NavLink>
                                </div>
                                <div>
                                    <NavLink to='epfandmpact' className='links'>EPF & MP Act</NavLink>
                                </div>
                                <div>
                                    <NavLink to='esiact' className='links'>ESI Act</NavLink>
                                </div>
                                <div>
                                    <NavLink to='shopandestablishment' className='links'>Shop & Establishment</NavLink>
                                </div>
                                <div>
                                    <NavLink to='factoryact' className='links'>Factories Act</NavLink>
                                </div>
                                <div>
                                    <NavLink to='staffingsolutions' className='links'>Staffing Solutions</NavLink>
                                </div>

                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                            <div className='our-links'>
                                <h2>Quick Links</h2>
                                <div>
                                    <NavLink to='about-us' className='links'>About Us</NavLink>
                                </div>
                                {/* <div>
                                    <NavLink to='ourclient' className='links'>Our Client</NavLink>
                                </div> */}
                                <div>
                                    <NavLink className='links'>Privacy Policy</NavLink>
                                </div>
                                <div>
                                    <NavLink to='contact-us' className='links'>Contact Us</NavLink>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='our-links'>
                                <div className='contact-details'>
                                    <h2>Address</h2>
                                    <h6>AB SQUARE Facility Management Services Pvt. Ltd.</h6>
                                    <Row>
                                        <Col xs={1} md={1} lg={1} className='add-icons'>
                                            <HiBuildingOffice />
                                        </Col>
                                        <Col xs={11} md={11} lg={11} className='mb-2'>
                                            <div>
                                                A-4, Qutub Vihar, Phase-II,
                                                Dwarka Sec-19, Near UER-II,
                                                New Delhi-110071
                                            </div>
                                        </Col>
                                        <Col xs={1} md={1} lg={1} className='add-icons'>
                                            <IoIosCall />
                                        </Col>
                                        <Col xs={11} md={11} lg={11} className='mb-2'>
                                            <div>
                                                <a href={`tel:+91 8588837004`}>
                                                    +91 85888 37004
                                                </a>
                                            </div>
                                            <div>
                                                <a href={`tel:+91 9717866586`}>
                                                    +91 97178 66586
                                                </a>
                                            </div>
                                        </Col>
                                        <Col xs={1} md={1} className='add-icons'>
                                            <IoMdMail />
                                        </Col>
                                        <Col xs={11} md={11} lg={11} >
                                            <div>
                                                <a href='mailto:absquarefacility@gmail.com'>
                                                    absquarefacility@gmail.com
                                                </a>
                                            </div>
                                            <div>
                                                <a href='mailto:contact@manpowercompany.in'>
                                                    contact@manpowercompany.in
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="padds p-0">
                        <Col xs={12} md={12}>
                            <div className='powered'>
                                © 2023 All Right Reserved by <span>AB SQUARE Facility Management Services Pvt. Ltd.</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default Footer