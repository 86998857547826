import React from 'react'
import './getInTouchButton.css'
import { NavLink } from 'react-router-dom'
const FloatButton = () => {
    return (
        <NavLink to="contact-us" className='contactbutton' data-aos='fade-down-right'>
            Get in Touch
        </NavLink>
    )
}

export default FloatButton