import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/esi.png'

const ESIAct = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section' data-aos='fade-left' >
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>ESI Act Conformity Services</h2>
                                        <p>
                                            Ensuring adherence to the Employees’ State Insurance (ESI) Act is an essential obligation for businesses throughout India. At Manpower Company, we understand the importance of ESI compliance and provide an extensive range of services.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2' data-aos='zoom-in-down'>
                                    <h3>
                                        Optimize ESI Act Adherence with <span>Manpower Company</span>
                                    </h3>
                                    <p>
                                        Guaranteeing adherence to the Employees' State Insurance (ESI) Act stands as a crucial obligation for enterprises nationwide. At Manpower Company, we acknowledge the paramount importance of ESI compliance and present an all-encompassing array of solutions meticulously crafted to aid you in smoothly maneuvering through the complexities of the ESI Act. Our proficient team is committed to streamlining ESI compliance, empowering you to concentrate on your fundamental business undertakings with certainty.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Our Services for ensuring ESI Act compliance:</h5>
                                    <ol>
                                        <li><strong>ESI Enrollment Support:</strong> Starting off on the right track is crucial. Our team assists you through the ESI enrollment process, ensuring a strong foundation for compliance.</li>
                                        <li><strong>Monthly ESI Contribution Calculation:</strong> Precise calculation of ESI contributions is vital for compliance maintenance. We ensure accurate calculation and timely deposit of contributions to the ESI authorities.</li>
                                        <li><strong>ESI Record Keeping:</strong> Maintaining accurate records is essential for compliance audits. We aid in record maintenance and management, ensuring they are current and easily accessible.</li>
                                        <li><strong>ESI Return Submission:</strong> Submitting ESI returns on time is critical to avoid penalties. Our experts handle the entire process, from preparation to submission, ensuring accuracy and compliance.</li>
                                        <li><strong>Compliance Audits and Advisory:</strong> Our team conducts thorough compliance audits to detect potential issues and provide proactive solutions. We offer expert advice to ensure the solidity of your compliance procedures.</li>
                                    </ol>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Why Opt for ESI Act compliance?</h5>
                                </div>
                            </Col>

                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h6><b>1. Medical Benefits:</b></h6>
                                    <ul>
                                        <li>Provides full medical care to the employee registered under ESI and their family members from day one of insurable employment. This includes not only treatment but also facilities such as hospitalization and surgery.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h6><b>2. Sickness Benefits:</b></h6>
                                    <ul>
                                        <li>Financial assistance is offered in the form of sickness benefits at approximately 70% of the employee’s daily wages for any certified sickness lasting a maximum of 91 days in any year.</li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h6><b>3. Maternity Benefits:</b></h6>
                                    <ul>
                                        <li>Pregnant employees can avail of maternity benefits that are payable for up to 26 weeks. This can be extended by another month on medical advice at full pay, inclusive of medical allowance.</li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h6><b>4. Disability Benefits:</b></h6>
                                    <ul>
                                        <li>In case of disability caused by employment injury or occupational disease, the employee is entitled to a monthly pension during the period of disability.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h6><b>5. Dependents’ Benefits:</b></h6>
                                    <ul>
                                        <li>In the event of the death of an employee due to an employment injury or occupational disease, the dependents will receive a monthly pension</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h6><b>6. Funeral Expenses:</b></h6>
                                    <ul>
                                        <li>Immediate family members are entitled to a lump sum payment made towards funeral expenses upon the death of the employee.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h6><b>7. Unemployment Allowance:</b></h6>
                                    <ul>
                                        <li>Under the Rajiv Gandhi Shramik Kalyan Yojana, unemployed insured persons can receive an unemployment allowance if they are rendered unemployed due to a factory closure, retrenchment, or permanent invalidity.</li>
                                    </ul>
                                </div>
                            </Col>


                            <Col xs={12}>
                                <div class='content-container2' data-aos='zoom-in-up'>
                                    <h5>Get started with Manpower Company ESI Act Compliance Services:</h5>
                                    <p>
                                        Simplifying ESI compliance requirements doesn't have to be daunting. Let Manpower Company streamline the process for you. Our dedicated team is committed to providing dependable ESI compliance services tailored to your business objectives.
                                    </p>
                                    <div class='sub-heading'>
                                        Contact Us Now:
                                    </div>
                                    <p>
                                        Connect with our team to discover how we can support you in achieving seamless ESI Act compliance. From registration to record maintenance and return filing, we're here to assist your compliance journey with effectiveness and efficiency. Your compliance is our top priority, and your success is our ultimate goal.
                                    </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default ESIAct