import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/wages.png'

const MinimumWagesAct = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>Minimum Wages Act, 1948</h2>
                                        <p>
                                            Elevate your organization's commitment to employee well-being and compliance with Manpower Company's Labor Welfare Fund Act, 1965 services. Reach out to us today to discover how we can assist you in creating a thriving workplace that values and supports its workforce.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2'>
                                    <h3>
                                        Services for Ensuring Compliance with the Minimum Wages Act, 1948
                                    </h3>
                                    <p>
                                        We understand the importance of providing fair compensation to foster a motivated and content workforce. Our specialized services for compliance with the Minimum Wages Act, 1948, are designed to support your organization in meeting the statutory requirements of this vital law. This ensures that your employees are duly compensated for their hard work.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2'>
                                    <h5>Services ensuring with the Minimum Wages Act</h5>
                                    <ol>
                                        <li><b>Comprehensive Guidance:</b> Our team of experts possesses in-depth knowledge of the Minimum Wages Act, 1948. We provide expert guidance to assist you in understanding and implementing the Act's provisions accurately, ensuring compliance while fostering a positive workplace environment.</li>
                                        <li><b>Wage Structure Evaluation:</b> We help you assess your current wage structure to ensure compliance with the Act's requirements. This includes determining minimum wages for various categories of employees based on skills, regions, and industries.</li>
                                        <li><b>Documentation Support:</b> Compliance often involves extensive documentation. We support you in maintaining precise records, registers, and documentation mandated by the Act, ensuring readiness for inspections and audits.</li>
                                        <li><b>Regular Updates:</b> Labor laws evolve, and compliance requirements change accordingly. Our services include keeping you updated on any amendments or revisions to the Minimum Wages Act, allowing you to make timely adjustments to your compensation practices.</li>
                                    </ol>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2'>
                                    <h5>Compliance with the Minimum Wages Act, 1948</h5>
                                    <p>
                                        Under the Minimum Wages Act 1948, all companies operating in India are required to remunerate specified categories of labor or employees with the minimum wages set by the Labour Law department, as periodically notified. This legislation mandates employers to adhere to the minimum wage rates prescribed under the Act.
                                    </p>
                                    <p>
                                        Both the Central Government and State Governments hold the authority to assess and establish minimum wage standards, as well as enforce regulations upon employers within their respective jurisdictions. At Manpower Company, we offer comprehensive consultancy services and assistance to businesses, ensuring smooth operations and compliance with legal requirements.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div class='content-container2'>
                                    <h5>How Can We Assist with Compliance under the Minimum Wages Act, 1948?</h5>
                                    <ul>
                                        <li>
                                            We compile, maintain, and submit all required reports and registers in accordance with the guidelines, including:
                                            <ul>
                                                <li>Keeping wage registers in Form No. X up to date</li>
                                                <li>Recording overtime in Form No. IV</li>
                                                <li>Maintaining Muster Roll in Form No. V</li>
                                                <li>Distributing wage slips as per Form No. XI</li>
                                                <li>Preparing and submitting Annual Return Form-III and other necessary forms</li>
                                            </ul>
                                        </li>
                                        <li>We offer comprehensive assistance regarding business operations in compliance with regulations.</li>
                                        <li>Our consultancy services provide regular updates to businesses concerning any changes or amendments related to the Minimum Wages Act 1948.</li>
                                    </ul>
                                    <p>For business owners, it can be challenging to stay abreast of all laws and acts, as well as amendments and notifications under applicable labor laws.</p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default MinimumWagesAct