import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/factory.png'

const FactoryAct = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section' data-aos='fade-left'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>Factories Act Compliance Services</h2>
                                        <p>We recognize the utmost significance of complying with the regulations set forth in the Factories Act. Our specialized Factories Act Compliance Services aim to offer your organization thorough support</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2' data-aos='zoom-in-down'>
                                    <h3>
                                        Holistic Solutions for Factories Act Compliance
                                    </h3>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Adherence to Factory Act, 1948 regulations</h5>
                                    <p>The Factory Act of 1948 outlines guidelines for ensuring the safety and health of workers in factories. At Manpower Company, we offer premium consultancy services tailored to businesses regulated under this Act.</p>
                                    <p>We provide expert advice on regulating working conditions, including working hours and holidays, while ensuring full compliance with government policies concerning worker welfare, health, and safety.</p>
                                    <p>Our experienced and dedicated team at Manpoer Company ensures strict adherence to government protocols and maintains all necessary reports and records mandated by the Factories Act of 1948.</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Why the Need of Services from Manpower Company</h5>
                                    <p>
                                        Being the owner of any business or factory, it is not possible to know and understand each and every law set by the government. However, in the case of any small breach, it may attract heavy penalties along with legal proceedings too.
                                    </p>
                                    <p>
                                        Thus, Manpower Company provides the best consultancy services from the most experienced and professional staff to ensure the proper functioning of the work and ensure compliance, as required as per the Factories Act, 1948 and other laws, as may be applicable from time to time.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <div className='sub-heading'>
                                        Below is the list of different services provided by us:-
                                    </div>
                                    <ul>
                                        <li>Factory registration and obtaining the factory license from the relevant department.</li>
                                        <li>Establishment, upkeep, and submission of all statutory records, returns, etc., in accordance with the Factories Act, 1948.</li>
                                        <li>Ensuring compliance with government-issued guidelines.</li>
                                        <li>Seamless operation of the business.</li>
                                    </ul>
                                    <p>
                                        Additionally, we handle the maintenance and submission of all records, including returns, registers, and reports mandated by the Act. Our commitment extends to keeping factory owners informed about any changes, amendments, or updates in laws and guidelines, ensuring smooth and effective operations.
                                    </p>
                                    <p>
                                        Registration of the factory and acquisition of the Factory License from the Department. Maintenance and submission of requisite statutory Records/Returns, etc., in compliance with the Factories Act of 1948.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Returns</h5>
                                    <div className='sub-heading'>
                                        Return Name/Act: Form Number
                                    </div>
                                    <ul>
                                        <li>Factories Act, 1948: Form No. 34</li>
                                        <li>Payment of Wages Act, 1936: Form IV-A</li>
                                        <li>Minimum Wages Act & Rules: Form No. III</li>
                                        <li>Workmen's Compensation Act: Annual Return</li>
                                        <li>Maternity Benefit Act, 1961: L.M.N.</li>
                                        <li>Half-Yearly Return: Form No. 22</li>
                                    </ul>
                                    <h5>Registers</h5>
                                    <ul>
                                        <li>Register of White Washing: Form No. 7 (Prescribed under Rule 18)</li>
                                        <li>Register of Leave with Wages: Form No. 15 (Prescribed under Rule 94)</li>
                                        <li>Register of Accidents & Dangerous Occurrences: Form No. 26 (Prescribed under Rule III)</li>
                                        <li>Register of Wages: Form No. 17 (Computerized)</li>
                                        <li>Muster Roll: Form No. 25 (Prescribed under Rule 110)</li>
                                        <li>Wage Slips: Form No. 19 (Computerized)</li>
                                    </ul>
                                    <h5>Yearly Maintenance of Testing Reports</h5>
                                    <ul>
                                        <li>Vessel Examination Report: Form 8 (Annual)</li>
                                        <li>Hoist or Lift Report: Form 7B (Annual)</li>
                                        <li>Pressure Vessel Testing & Examination: Form 8 (Annual) for Steam Generators/Steam Presses</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default FactoryAct