import React from 'react'
import './headerTitle.css'
import { Breadcrumb } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
const HeaderTitle = (props) => {
    return (
        <div className='header-title-container' data-aos='zoom-in-left'>
            <NavLink to='/' className="link">Home</NavLink>
            <div>
                / {props.title}
            </div>
        </div>
    )
}

export default HeaderTitle