import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import brochure from '../../assets/brochure.pdf';
import './drawer.css'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { CgClose, CgMenuRight } from 'react-icons/cg';

const Drawer = ({ isOpen, toggleDrawer, navData }) => {
    const [subMenu, setSubMenu] = useState(false)

    const toggleSubMenu = () => {
        setSubMenu(!subMenu)
    }

    const handleNavigate = () => {
        setSubMenu(false)
        toggleDrawer()
    }

    return (
        <>
            {isOpen && <BackDrop onClick={toggleDrawer} />}
            <SDrawer isOpen={isOpen}>
                <div className='mob-routes'>
                    <div className='ul'>
                        {navData.map((item) => {
                            return (
                                <div className='li' onClick={item.subItem ? null : toggleDrawer}>
                                    {item.subItem ?
                                        <div to={item.path} className='li-item-sub' onClick={toggleSubMenu}  >
                                            {item.label} {subMenu ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                        </div>
                                        :
                                        <NavLink to={item.path} className='li-item'  >
                                            {item.label}
                                        </NavLink>
                                    }

                                    {item?.subItem && subMenu &&
                                        <div className='dropdown'>
                                            {item?.subItem?.map((subitem) => {
                                                return (
                                                    <div
                                                    onClick={handleNavigate}
                                                    >
                                                        <NavLink to={subitem.path} className='dropdown-item'>
                                                            {subitem.label}
                                                        </NavLink>
                                                    </div>
                                                )
                                            })}
                                        </div>}
                                </div>
                            )
                        })}
                        <a href={brochure} download={'Manpower_Company_Brochure.pdf'} className='brochure-btn'>
                            Get Brochure
                        </a>
                        <div className='closeIcon' onClick={toggleDrawer}>
                            <CgClose />
                        </div>
                    </div>

                </div>
            </SDrawer>
        </>
    )
}

export default Drawer


const BackDrop = styled.div`
height:100%;
width:100%;
z-index:100;
position:fixed;
top:0;
left:0;
background-color:rgba(0, 0, 0 ,0.5);
transition:0.3s ease`;
const SDrawer = styled.div`
padding:20px;
z-index:150;
position:fixed;
height:100vh;
width:70%;
background-color:white;
transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
transition:0.3s ease
`;