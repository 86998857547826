import React from 'react'
import '../../css/EPFandMPact.css'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/staffing.png'
import { NavLink } from 'react-router-dom'

const StaffingSolutions = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact'>
                    <div className='top-section' data-aos='fade-left'>
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>Staffing Solutions
                                        </h2>
                                        <p>
                                            Manpower Company is a leading provider of Staffing Services and Third Party Manpower Solutions. Committed to delivering excellence, we facilitate connections between businesses and highly skilled professionals, optimizing operations.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-4'>
                                <div className='content-container2' data-aos='zoom-in-down'>
                                    <h3>
                                        Top-tier Staffing Services and Third-Party Manpower Solutions
                                    </h3>
                                    <p>
                                        At Manpower Company, we recognize the pivotal role a skilled workforce plays in driving business success. Our staffing solutions encompass a wide range of services tailored to address the ever-evolving staffing needs of businesses in various sectors. Whether you require temporary staffing or permanent recruitment, we excel in sourcing and delivering high-caliber talent that resonates with your organization's objectives.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Our Services:</h5>
                                    <ul>
                                        <li>
                                            <b>Flexible Staffing Solutions:</b> Access a versatile workforce to meet changing demands without incurring additional expenses.
                                        </li>
                                        <li><b>Tailored Recruitment Services:</b> Allow us to match your team with the ideal candidates from our extensive network and stringent selection process.</li>
                                        <li><b>Specialized Talent Acquisition:</b> Secure skilled professionals for short-term projects, ensuring efficient project delivery.</li>
                                        <li><b>Efficient Payroll Management:</b> Simplify your HR operations by outsourcing payroll processing and compliance tasks to us.</li>
                                        <li><b>Streamlined Workforce Management:</b> Enhance operational efficiency by leveraging our comprehensive managed services for workforce optimization.</li>
                                        <li><b>Industry-Specific Insights:</b> Benefit from the expertise of our seasoned professionals who possess in-depth knowledge of diverse industries, enabling targeted staffing solutions.
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Why Choose Manpower Company?</h5>
                                    <ul>
                                        <li><b>Proven Expertise:</b> Our extensive industry tenure and successful placements underscore our proficiency.</li>
                                        <li><b>Premium Talent Pool:</b> Thorough vetting ensures that only top-tier candidates are presented for your consideration.</li>
                                        <li><b>Tailored Solutions:</b> We customize our offerings to align seamlessly with your specific requirements, ensuring optimal integration.</li>
                                        <li><b>Efficiency and Cost-Effectiveness:</b> Our streamlined processes deliver significant time and cost savings throughout the recruitment journey.</li>
                                        <li><b>Client-Focused Approach:</b> Your satisfaction is our paramount concern, and we continuously strive to surpass your staffing needs.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default StaffingSolutions