import React from 'react'
import './features.css'
import feature1 from '../../../assets/images/features/feature1.png'
import feature2 from '../../../assets/images/features/feature2.png'
import feature3 from '../../../assets/images/features/feature3.png'
import feature4 from '../../../assets/images/features/feature4.png'
import feature5 from '../../../assets/images/features/feature5.png'

const Features = () => {
    return (
        <section id="advertisers" class="advertisers-service-sec pt-2 pb-2" data-aos='fade-down-right'>
            <div class="container">
                <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-5 justify-content-space-between">
                    <div class="col">
                        <div class="service-card">
                            <div class="icon-wrapper">
                                <div className='features-card-image-container'>
                                    <img src={feature1} className='features-card-image' />
                                </div>
                            </div>
                            <h3>Effective and reliable services.</h3>
                        </div>
                    </div>
                    <div class="col">
                        <div class="service-card">
                            <div class="icon-wrapper">
                                <div className='features-card-image-container'>
                                    <img src={feature2} className='features-card-image' />
                                </div>
                            </div>
                            <h3>Team of competent professionals having expertise</h3>
                        </div>
                    </div>
                    <div class="col">
                        <div class="service-card">
                            <div class="icon-wrapper">
                                <div className='features-card-image-container'>
                                    <img src={feature3} className='features-card-image' />
                                </div>
                            </div>
                            <h3>Our expertise in assessing problem areas.</h3>
                        </div>
                    </div>
                    <div class="col">
                        <div class="service-card">
                            <div class="icon-wrapper">
                                <div className='features-card-image-container'>
                                    <img src={feature4} className='features-card-image' />
                                </div>
                            </div>
                            <h3>Cost effective solutions.</h3>
                        </div>
                    </div>
                    <div class="col">
                        <div class="service-card">
                            <div class="icon-wrapper">
                                <div className='features-card-image-container'>
                                    <img src={feature5} className='features-card-image' />
                                </div>
                            </div>
                            <h3>Timely delivery.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features