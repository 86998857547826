import React from 'react'
import '../../css/EPFandMPact.css'
import { Accordion, AccordionItem, Col, Container, Row } from 'react-bootstrap'
import image from '../../assets/images/servicesImage/manPowerServices.png'

const ManpowerServices = () => {
    return (
        <>
            <section>
                <div className='EPFandMPact' >
                    <div className='top-section' data-aos='fade-left' >
                        <Container>
                            <Row className='mb-2 py-2 align-items-center'>
                                <Col xs={12} md={5}>
                                    <div className='image-container'>
                                        <img src={image} />
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='content-container'>
                                        <h2>Manpower Services</h2>
                                        <p>
                                            With over 10 years of experience and more than 100 satisfied clients, we have proven our reliability and effectiveness in handling complex workforce needs.
                                            <br></br>
                                            we are proud to offer a range of specialized services tailored to meet the diverse needs of our clients.
                                        </p>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} className='py-2'>
                                <div className='content-container2' data-aos='zoom-in-down'>
                                    <h3>
                                        Delivering Quality, Effective
                                        & Professional Manpower
                                        Services
                                    </h3>
                                    <p>
                                        Our commitment lies in delivering top-tier Manpower services that epitomize quality, effectiveness, and professionalism. With a meticulous selection process and rigorous training protocols, we ensure that our workforce not only meets but exceeds the expectations of our clients. Our dedicated team is equipped with the skills, expertise, and dedication to seamlessly integrate into diverse work environments, providing valuable support and contributing to the success to every project. Whether it's temporary staffing, permanent staffing or specialized Manpower solutions, our unwavering focus remains on delivering excellence in service with competitive pricing and quality services which empowering businesses to thrive and flourish.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} className='mb-4'>
                                <div className='content-container2' data-aos='zoom-in-up'>
                                    <h5>Services we serve-</h5>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> <b>⦿	Industry & Factory Labour </b></Accordion.Header>
                                            <Accordion.Body>
                                                <p><b>General Labour:</b>  Including unskilled and semi-skilled workers for various industrial tasks.</p>
                                                <p><b>Specialized Labour:</b> Skilled workers trained for specific roles within manufacturing and production environments.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header> <b>⦿	Helpers, Packers </b></Accordion.Header>
                                            <Accordion.Body>
                                                <p><b>Logistics Support:</b> Essential staff for packaging, loading, unloading of goods, crucial for distribution and logistics sectors.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header> <b>⦿ Production & Warehouse Executives </b></Accordion.Header>
                                            <Accordion.Body>
                                                <p><b>Operational Staff:</b> Efficiently manage production lines and warehouse operations, including inventory management and quality control.</p>
                                                <p><b>Ideal Industries:</b></p>
                                                <ul>
                                                    <li>Manufacturing</li>
                                                    <li>Automotive</li>
                                                    <li>Pharmaceuticals</li>
                                                    <li>Electronics</li>
                                                    <li>Food and Beverages</li>
                                                    <li>Textiles</li>
                                                    <li>Chemicals</li>
                                                    <li>Consumer Goods & many more</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header> <b>⦿ General Support Services </b></Accordion.Header>
                                            <Accordion.Body>
                                                <p><b>Housekeeping:</b> Ensures facilities are clean and orderly, maintaining a hygienic and welcoming environment.</p>
                                                <p><b>Pantry Services: </b>Efficient management of pantry supplies and operations, including the serving of water, tea, coffee, and food to maintain a hospitable workplace.</p>
                                                <p><b>Peon/ Office Boy:</b> Perform essential office routine tasks, office maintenance and general maintenance to ensure smooth daily operations.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header> <b>⦿ Drivers </b></Accordion.Header>
                                            <Accordion.Body>
                                                <p><b>Commercial and Personal Drivers:</b> Licensed and experienced drivers for corporate, commercial, or personal vehicles.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header> <b>⦿ Daily wages labour for several works </b></Accordion.Header>
                                            <Accordion.Body>
                                                <p><b>Flexible Staffing Solutions:</b> Available for various short-term or project-specific tasks across multiple industries</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header> <b>⦿ Maintenance Staff</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p><b>Skilled Technicians: </b>Including electricians, plumbers, carpenters, and other technicians to maintain and repair infrastructure.</p>
                                                <p><b>Emergency and Routine Maintenance:</b> Rapid response teams and scheduled maintenance services.</p>
                                                <p><u>Ex:</u> Electrician, Plumber, Carpenter, Technician etc.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header> <b>⦿ Office Helpers/ Assistant Staff</b></Accordion.Header>
                                            <Accordion.Body>

                                                <p><b>Office Helpers/Assistant Staff:</b> Provide support with tasks such as filing, photocopying, calls & messages handling, managing communications, organizing meetings, data entry, greeting visitors and ensuring seamless office operations and maintaining efficiency.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default ManpowerServices