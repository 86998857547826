import React from 'react'
import './growing.css'
import { Col, Container, Row } from 'react-bootstrap'
import card1 from '../../../assets/images/grow-icons/clients.png'
import card2 from '../../../assets/images/grow-icons/chart.png'
import card3 from '../../../assets/images/grow-icons/employees.png'
const Growing = () => {
    return (
        <section >
            <div className='growing' >
                <Container>
                    <Row className='g-4'>
                        <Col xs={12} md={12}>
                            <div className='sec-heading'>
                                Our continuously  <span>expanding influence </span>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='grow-card' data-aos='fade-left'>
                                <div className='card-content'>
                                    <div className='head'>
                                        <img src={card1} />
                                        <div className='heading'>
                                            100+
                                        </div>
                                    </div>
                                    <div className='text'>Happy Clients</div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='grow-card' data-aos='fade-down'>
                                <div className='card-content'>
                                    <div className='head'>
                                        <img src={card2} />
                                        <div className='heading'>
                                            10+
                                        </div>
                                    </div>
                                    <div className='text'> Years of Experience</div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='grow-card' data-aos='fade-right'>
                                <div className='card-content'>
                                    <div className='head'>
                                        <img src={card3} />
                                        <div className='heading'>
                                            700+
                                        </div>
                                    </div>
                                    <div className='text'>Employees</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default Growing