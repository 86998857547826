import React, { useState, useEffect } from 'react'
import './navbar.css'
import { NavLink, useSearchParams } from 'react-router-dom'
import logo from '../../assets/images/ManPower_logo.png'

import { CgMenuRight } from "react-icons/cg";
import { IoMdCloseCircleOutline } from "react-icons/io";
import brochure from '../../assets/brochure.pdf';
const NavBar = ({ toggleDrawer, navData }) => {


    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });


    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('.navbar-container');
        const scrollTop = window.scrollY;
        scrollTop >= 80 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    return (
        <div className='navbar-container'>
            <div className='logo-container'>
                <NavLink to='/' >
                    <img src={logo} />
                </NavLink>
            </div>
            <div className='routes'>
                <div className='ul'>
                    {navData.map((item) => {
                        return (
                            <>
                                <div className='li'>
                                    {item.subItem ?
                                        <div to={item.path} className='li-item'  >
                                            {item.label}
                                        </div>
                                        :
                                        <NavLink to={item.path} className='li-item'  >
                                            {item.label}
                                        </NavLink>
                                    }
                                    {item?.subItem &&
                                        <div className='dropdown'>
                                            {item?.subItem?.map((subitem) => {
                                                return (
                                                    <NavLink to={subitem.path} className='dropdown-item'>
                                                        {subitem.label}
                                                    </NavLink>
                                                )
                                            })}
                                        </div>}
                                </div>
                            </>
                        )
                    })}
                </div>
                <a href={brochure} download={'Manpower_Company_Brochure.pdf'} className='contact-button'>
                    Get Brochure
                </a>
            </div>
            <div className='menuIcon' onClick={toggleDrawer}>
                <CgMenuRight />
            </div>
        </div>
    )
}

export default NavBar