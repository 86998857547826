import React, { useEffect } from 'react'
import '../css/home.css'
import Slider from '../components/slider/Slider'
import Features from '../components/homeComponents/features/Features'
import AboutCom from '../components/homeComponents/about/AboutCom';
import OurServices from '../components/homeComponents/ourServices/OurServices';
import OurClients from '../components/homeComponents/ourClients/OurClients';
import WhyChoose from '../components/homeComponents/whyChoose/WhyChoose';
import Growing from '../components/homeComponents/growing/Growing';

const Home = () => {
    return (
        <div>
            <Slider />
            <AboutCom />
            <Features />
            <OurServices />
            <OurClients />
            <WhyChoose />
            <Growing />
        </div >
    )
}

export default Home