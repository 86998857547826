import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnNavigate = () => {
    const { pathname } = useLocation();
    const prevPathname = useRef('');

    useEffect(() => {
        // Scroll to the top only when the pathname changes (i.e., route changes)
        if (pathname !== prevPathname.current) {
            window.scrollTo(0, 0);
            prevPathname.current = pathname;
        }
    }, [pathname]);

    return null;
};

export default ScrollToTopOnNavigate;

