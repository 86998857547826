import React, { useEffect, useRef } from 'react'
import './ourClients.css'
import Slider from 'react-slick'
import { clientsData } from './clientsData';
import { GrNext, GrPrevious } from "react-icons/gr";
import { NavLink } from 'react-router-dom';

const OurClients = () => {

    const sliderRef = useRef(null);
    var settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 7,
        slidesToScroll: 5,
        arrows: false,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <section data-aos='zoom-in-down'>
            <div className='ourClients' >
                <div className='container'>
                    <div className='row '>
                        <div className="col-sm-12 col-md-12 ">
                            <div className='sec-heading'>
                                Our Clients
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 ">
                            <div className='mb-4'>
                                <Slider ref={sliderRef} {...settings}>
                                    {clientsData.map((item) => {
                                        return (
                                            <div className='p-2'>
                                                <div className='slider-client-item'>
                                                    <img src={item.img} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurClients