import React from 'react'
import './whyChoose.css'
import experience from '../../../assets/images/whywe/experience.png'
import professionals from '../../../assets/images/whywe/professionalteam.png'
import quality from '../../../assets/images/whywe/quality.png'
import customercare from '../../../assets/images/whywe/customercare.png'
import { Col, Container, Row } from 'react-bootstrap'

const WhyChoose = () => {
    return (
        <section>
            <Container>
                <Row>
                    <Col md={6} xs={12}>
                        <div className='content' data-aos='zoom-out-down'>
                            <h3>WHY CHOOSE</h3>
                            <span>Manpower Company </span>
                            <p>
                                Manpower company is providing the services of total staffing solution & payroll management in India since more than 10+ years. With such a long experience in the field, we provide the best and easiest solution with categories of manpower like Unskilled, Semi-skilled and Skilled staff workforce, contract staff, payroll management with maintenance of different statutory records, calculation and payment of wages at the most efficient way and various other responsibilities. Manpower Company is your reliable partner for all your staffing and human resource needs in India. Our expertise, comprehensive solutions, depth of knowledge, skills, experience and client-centric approach make us the ideal choice to ensure your all your manpower need & payroll processes to make it efficient, cost effective, risk & hassle-free management. When considering a partner for all your needs in India, there are several compelling reasons to choose Manpower Company. Our commitment to excellence, comprehensive services, technological prowess, and client-centric approach set us apart as a top choice in the industry.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className='content-cards' data-aos='zoom-out-down' >
                            <Row className="justify-content-space-between" >
                                <Col md={6} xs={12} className="mb-4">
                                    <div class="flip-card">
                                        <div class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <img src={experience} />
                                                <div class="flip-text">Experience & Expertise</div>
                                            </div>
                                            <div class="flip-card-back">
                                                <div class="content-container">
                                                    <p>
                                                        Over 10 years in Indian manpower & payroll management, our expertise ensures accurate, compliant, and efficient solutions.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={12} className="mb-4">
                                    <div class="flip-card">
                                        <div class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <img src={professionals} />
                                                <div class="flip-text">Professional team</div>
                                            </div>
                                            <div class="flip-card-back">
                                                <div class="content-container">
                                                    <p>
                                                        Our skilled professionals guarantee meticulous manpower & payroll management tailored to your needs with precision.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={12} className="mb-4">
                                    <div class="flip-card">
                                        <div class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <img src={quality} />
                                                <div class="flip-text">Quality Services</div>
                                            </div>
                                            <div class="flip-card-back">
                                                <div class="content-container">
                                                    <p>
                                                        Equipped with modern technology and resources, we streamline manpower & payroll processes for seamless efficiency.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={12} className="mb-4">
                                    <div class="flip-card">
                                        <div class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <img src={customercare} />
                                                <div class="flip-text">Prompt Client Support</div>
                                            </div>
                                            <div class="flip-card-back">
                                                <div class="content-container">
                                                    <p>
                                                        Our dedicated support ensures prompt assistance, making your experience with us smooth and hassle-free.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default WhyChoose