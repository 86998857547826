import React, { useState } from 'react'
import NavBar from '../navBar/Navbar'
import { navData } from '../../config/NavData'
import Drawer from './Drawer'
const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Drawer navData={navData} isOpen={isOpen} toggleDrawer={toggleDrawer} />
            <NavBar navData={navData} toggleDrawer={toggleDrawer} />
        </>
    )
}

export default Navigation