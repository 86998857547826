import one from '../../../assets/images/clients/1.jpg'
import two from '../../../assets/images/clients/2.jpg'
import three from '../../../assets/images/clients/3.jpg'
import four from '../../../assets/images/clients/4.jpg'
import five from '../../../assets/images/clients/5.jpg'
import six from '../../../assets/images/clients/6.jpg'
import seven from '../../../assets/images/clients/7.jpg'
import eight from '../../../assets/images/clients/8.jpg'
import nine from '../../../assets/images/clients/9.jpg'
import ten from '../../../assets/images/clients/10.jpg'
import eleven from '../../../assets/images/clients/11.jpg'
import twelve from '../../../assets/images/clients/12.jpg'
import thirteen from '../../../assets/images/clients/13.jpg'
import fourteen from '../../../assets/images/clients/14.jpg'
import fifteen from '../../../assets/images/clients/15.jpg'
import sixteen from '../../../assets/images/clients/16.jpg'
import seventeen from '../../../assets/images/clients/17.jpg'
import eightteen from '../../../assets/images/clients/18.jpg'
import nineteen from '../../../assets/images/clients/19.jpg'
import twenty from '../../../assets/images/clients/20.jpg'
import twentyone from '../../../assets/images/clients/21.jpg'
import twentytwo from '../../../assets/images/clients/22.jpg'
import twentythree from '../../../assets/images/clients/23.jpg'
import twentyfour from '../../../assets/images/clients/24.jpg'
import twentyfive from '../../../assets/images/clients/25.jpg'
import twentysix from '../../../assets/images/clients/26.jpg'
import twentyseven from '../../../assets/images/clients/27.jpg'
import twentyeight from '../../../assets/images/clients/28.jpg'
import twentynine from '../../../assets/images/clients/29.jpg'
import thirty from '../../../assets/images/clients/30.jpg'
import thirtyone from '../../../assets/images/clients/31.jpg'
import thirtytwo from '../../../assets/images/clients/32.jpg'
import thirtythree from '../../../assets/images/clients/33.jpg'



export const clientsData = [
    {
        id: 1,
        img: one
    },
    {
        id: 2,
        img: two
    },
    {
        id: 3,
        img: three
    },
    {
        id: 4,
        img: four
    },
    {
        id: 5,
        img: five

    },
    {
        id: 6,
        img: six
    },
    {
        id: 7,
        img: seven
    },
    {
        id: 8,
        img: eight

    },
    {
        id: 9,
        img: nine
    },
    {
        id: 10,
        img: ten
    },
    {
        id: 11,
        img: eleven
    },
    {
        id: 12,
        img: twelve
    },
    {
        id: 13,
        img: thirteen
    },
    {
        id: 14,
        img: fourteen
    },
    {
        id: 15,
        img: fifteen
    },
    {
        id: 16,
        img: sixteen
    },
    {
        id: 17,
        img: seventeen
    },
    {
        id: 18,
        img: eightteen
    },
    {
        id: 19,
        img: nineteen
    },
    {
        id: 20,
        img: twenty
    },
    {
        id: 21,
        img: twentyone
    },
    {
        id: 22,
        img: twentytwo
    },
    {
        id: 23,
        img: twentythree
    },
    {
        id: 24,
        img: twentyfour
    },
    {
        id: 25,
        img: twentyfive
    },
    {
        id: 26,
        img: twentysix
    },
    {
        id: 27,
        img: twentyseven
    },
    {
        id: 27,
        img: twentyeight
    },
    {
        id: 29,
        img: twentynine
    },
    {
        id: 30,
        img: thirty
    },
    {
        id: 31,
        img: thirtyone
    },
    {
        id: 33,
        img: thirtytwo
    },
    {
        id: 33,
        img: thirtythree
    }

]