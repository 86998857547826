import React from 'react'
import HeaderTitle from '../components/headerTitle/HeaderTitle'
import EnquiryForm from '../components/contactComponents/enquiryForm/EnquiryForm'

const ContactUs = () => {
    return (
        <div>
            <HeaderTitle title={'Contact'} />
            <EnquiryForm />
        </div>
    )
}

export default ContactUs