import React, { useRef } from 'react'
import './ourServices.css'
import Slider from 'react-slick'
import { serviceData } from './servicesData'
import { GrNext, GrPrevious } from "react-icons/gr";
import { NavLink } from 'react-router-dom';

const OurServices = () => {

    const sliderRef = useRef(null);
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <section>
            <div className='ourService' data-aos='zoom-in-down'>
                <div className='container'>
                    <div class="row">
                        <div className="col-sm-12 col-md-4 title">
                            <div className='title-container'>
                                <h2>FEATURES
                                    <div>Our Services</div>
                                </h2>
                                <span>
                                    <span className='headings'>Comprehensive Staffing Solutions:</span>
                                    <p>Whether you need contract staff or permanent team members, we have the expertise to source and manage the right talent. </p>
                                </span>
                                <span>
                                    <span className='headings'>Advanced Payroll Management:</span>
                                    <p>We streamline payroll processes ensuring timely and accurate wage calculations and payments while maintaining compliance with statutory requirements.</p>
                                </span>
                                <div className='prev-next-container'>
                                    <div className='prev-next-btn' onClick={() => sliderRef?.current?.slickPrev()}>
                                        <GrPrevious />
                                    </div>
                                    <div className='prev-next-btn' onClick={() => sliderRef?.current?.slickNext()}>
                                        <GrNext />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-sm-12 col-md-8">
                            <Slider ref={sliderRef} {...settings}>
                                {serviceData.map((item) => {
                                    return (
                                        <div className='p-2'>
                                            <div className='slider-item'>
                                                <div className='img-container'>
                                                    <img src={item.img} />
                                                </div>
                                                <div>
                                                    <h3>{item.title}</h3>
                                                    <p>{item.subTitle}</p>
                                                </div>
                                                <NavLink className='read-button' to={item.path}>
                                                    Read more
                                                </NavLink>
                                            </div>
                                        </div>
                                    )
                                })}

                            </Slider>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    )
}

export default OurServices