import React, { useState } from 'react'
import './enquiryForm.css'
import { Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import contact from '../../../assets/images/contact.png'
import { FaLongArrowAltRight } from "react-icons/fa";
import axios from 'axios';
import Swal from 'sweetalert2';

const EnquiryForm = () => {
    const [data, setData] = useState({
        name: '',
        mobile: '',
        email: '',
        query: ''
    })
    const [sending, setSending] = useState(false)
    const [buttonenable, setButtonEnable] = useState(false)
    const [error, setError] = useState({})
    const { name, email, query, mobile } = data

    const handleChange = (field, value) => {
        setData({
            ...data,
            [field]: value
        })
    }

    const handleError = (field, value) => {
        setError((prev) => (
            {
                ...prev,
                [field]: value
            }
        ))
    }

    const validation = () => {
        var valid = true
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
        if (!name) {
            handleError('name', 'Please Input Name')
            valid = false
        }
        if (!mobile) {
            handleError('mobile', 'Please Input Mobile Number')
            valid = false
        }
        else if (mobile.length != 10) {
            handleError('mobile', 'Please Input Correct Mobile Number')
            valid = false
        }
        if (email) {
            if (!emailRegex.test(email)) {
                handleError('email', 'Please Input a valid Email');
                valid = false;
            }
        }
        return valid;
    }

    const handleSubmit = async () => {
        if (validation()) {
            setSending(true)
            setButtonEnable(true)
            var dataToSend = {
                name: name,
                mobile: mobile,
                email: email,
                query: query
            }
            try {
                var response = await axios.post('https://Manpower-backend.vercel.app/email/send_mail', dataToSend)
                console.log(response.data)
                if (response.data.status) {
                    setSending(false)
                    setButtonEnable(false)
                    setData({
                        name: '',
                        mobile: '',
                        email: '',
                        query: ''
                    })
                    setError({})
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Your Query has been Sent",
                        text: 'Our Team contact you soon...',
                        showConfirmButton: true,
                    });
                }
                else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Failed to send your Query",
                        text: 'Please Check Your Internet, then try again',
                        showConfirmButton: true,
                    });
                }
            }
            catch (e) {
                console.log(e)
                return false
            }
        }
    }
    return (
        <section>
            <Container className='p-2 p-md-4'>
                <Row className='g-2'>
                    <Col xs={12} md={5}>
                        <div className='enquiry-image-container'>
                            <img src={contact} />
                        </div>
                    </Col>
                    <Col xs={12} md={7}>
                        <div className='enquiry-form'>
                            <h2>
                                GET IN TOUCH
                            </h2>
                            <label>Name</label><span className='error'>{error.name}</span>
                            <input type='text' placeholder='Input Name' value={name}
                                onChange={(e) => handleChange('name', e.target.value)}
                                onFocus={() => handleError('name', null)}
                            />
                            <label>Mobile Number</label> <span className='error'>{error.mobile}</span>
                            <input type='numeric' placeholder='Input Mobile Number' value={mobile}
                                maxLength={10}
                                onChange={(e) => handleChange('mobile', e.target.value)}
                                onFocus={() => handleError('mobile', null)}
                            />
                            <label>Email Address</label><span className='error'>{error.email}</span>
                            <input type='numeric' placeholder='Input Email Address' value={email}
                                onChange={(e) => handleChange('email', e.target.value)}
                                onFocus={() => handleError('email', null)}
                            />
                            <label>Query</label><span className='error'>{error.query}</span>
                            <textarea type='numeric' className='textarea' placeholder='Input Query'
                                value={query}
                                onChange={(e) => handleChange('query', e.target.value)}
                                onFocus={() => handleError('query', null)}
                            />
                            <button className='submit-button' onClick={handleSubmit}
                                disabled={buttonenable}
                                style={{ cursor: buttonenable ? 'wait' : 'pointer' }}
                            >
                                {!sending ?
                                    <> Submit Query
                                        <FaLongArrowAltRight className='arrow-icon' />
                                    </>
                                    :
                                    <> Submitting {" "}
                                        <Spinner style={{ marginLeft: '10px', }} size="sm" />
                                    </>
                                }
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >

    )
}

export default EnquiryForm