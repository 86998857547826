import one from '../../../assets/images/services/EPF & MP Act.png'
import two from '../../../assets/images/services/Employee State Insurance Act.png'
import three from '../../../assets/images/services/Labour Welfare Fund.png'
import four from '../../../assets/images/services/Shop Establishment Act.png'
import five from '../../../assets/images/services/Factory Act, 1948.png'
import six from '../../../assets/images/services/Minimum Wages Act.png'
import seven from '../../../assets/images/services/Contract Labour (R&A) Act.png'
import eight from '../../../assets/images/services/Payroll processing.png'
export const serviceData = [
    {
        id: 1,
        title: 'EPF & MP Act',
        subTitle: 'Online Registration of establishment under EPF & MP Act, 1952...',
        img: one,
        path: 'epfandmpact'
    },
    {
        id: 2,
        title: 'Employee State Insurance Act',
        subTitle: 'Registration of establishment under ESI Act & obtaining Code No...',
        img: two,
        path: 'esiact'
    },
    {
        id: 3,
        title: 'Labour Welfare Fund',
        subTitle: "Calculation of contribution and deposited along with Form 'A’ within prescribed...",
        img: three,
        path: 'labourwelfareact'

    },
    {
        id: 4,
        title: 'Shop Establishment Act',
        subTitle: 'Correspondence and liaison with office of "Labour Commissioner"...',
        img: four,
        path: 'shopandestablishment'

    },
    {
        id: 5,
        title: 'Factory Act, 1948',
        subTitle: 'Factory Registration and obtaining Factory Licence from the Department...',
        img: five,
        path: 'factoryact'
    },
    {
        id: 6,
        title: 'Minimum Wages Act',
        subTitle: 'Wage Register in Form No. X...',
        img: six,
        path: 'minimumwagesact'
    },
    {
        id: 7,
        title: 'Manpower Services',
        subTitle: 'Comprehensive total staffing solutions tailored to meet the unique needs for your business...',
        img: seven,
        path: 'contractlabouract'
    },
    {
        id: 9,
        title: 'Payroll processing',
        subTitle: 'Payroll processing is an integral part of the corporate world...',
        img: eight,
        path: 'payrollprocessing'
    }
]