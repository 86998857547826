import React from "react"
import { Carousel } from "react-responsive-carousel"
import banner1 from '../../assets/images/banner/banner1.png'
import banner2 from '../../assets/images/banner/banner2.png'
import banner3 from '../../assets/images/banner/banner3.png'
const Slider = () => {
    return (
        <div data-aos='fade-up'>
            <Carousel
                showThumbs={false}
                showStatus={false}
                interval={2000}
                infiniteLoop
                swipeable={false}
            >
                <div>
                    <img src={banner1} />
                </div>
                <div>
                    <img src={banner2} />
                </div>
                <div>
                    <img src={banner1} />
                </div>
            </Carousel>
        </div>
    )
}
export default Slider