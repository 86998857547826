import React from 'react'
import './aboutCom.css'
import aboutImage from '../../../assets/images/homepic.png'
import { NavLink } from 'react-router-dom';
import { IoIosArrowDropright } from "react-icons/io";
const AboutCom = () => {
    return (
        <div className='about-container container' data-aos='fade-up'>
            <div className='about-image-container'>
                <img src={aboutImage} className='about-image' />
            </div>
            <div className='about-content'>
                <h3>About <span>Manpower Company </span></h3>
                <h2>A Leading Management
                    <span> & Payroll Outsourcing Company</span>
                </h2>
                <p>
                    At Manpower Company, we specialize in comprehensive staffing solutions and payroll management, serving a wide array of clients across Delhi & NCR (including Gurgaon, Noida, Ghaziabad, Faridabad etc.). As a distinguished professional consultant, we are dedicated to handling every aspect of your manpower needs.
                </p>
                <NavLink to='/about-us' className='about-view-button'>
                    <span>Read more</span>
                    <IoIosArrowDropright className='arrow' />
                </NavLink>
            </div>
        </div>
    )
}

export default AboutCom