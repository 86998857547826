import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Navigator from './navigators/Navigator'
import ScrollToTopOnNavigate from './config/ScrollToTopOnNavigate'
import Footer from './components/footer/Footer'
import GetInTouchButton from './components/getinTouchButton/GetInTouchButton'
import ScrollToTopButton from './components/scrollToTopButton/ScrollToTopButton'
import NavBar from './components/navBar/Navbar'
import Aos from 'aos';
import 'aos/dist/aos.css'
import Navigation from './components/navigation/Navigation'
const App = () => {
  useEffect(() => {
    Aos.init()
  })
  return (
    <Router>
      <ScrollToTopOnNavigate />
      <ScrollToTopButton />
      <Navigation />
      {/* <NavBar /> */}
      <GetInTouchButton />
      <Navigator />
      <Footer />
    </Router>
  )
}

export default App