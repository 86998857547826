import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import About from '../pages/About'
import ContactUs from '../pages/ContactUs'
import PayRollProcessing from '../pages/services/PayRollProcessing'
import EPFandMPact from '../pages/services/EPFandMPact'
import ShopAndEstablishment from '../pages/services/ShopAndEstablishment'
import FactoryAct from '../pages/services/FactoryAct'
import StaffingSolutions from '../pages/services/StaffingSolutions'
import ESIAct from '../pages/services/ESIAct'
import LabourWelfareAct from '../pages/services/LabourWelfareAct'
import MinimumWagesAct from '../pages/services/MinimumWagesAct'
import ContractLabourAct from '../pages/services/ContractLabourAct'
import ManpowerServices from '../pages/services/ManPowerServices'

const Navigator = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='about-us' element={<About />} />
            <Route path='contact-us' element={<ContactUs />} />
            <Route path='payrollprocessing' element={<PayRollProcessing />} />
            <Route path='epfandmpact' element={<EPFandMPact />} />
            <Route path='shopandestablishment' element={<ShopAndEstablishment />} />
            <Route path='factoryact' element={<FactoryAct />} />
            <Route path='staffingsolutions' element={<StaffingSolutions />} />
            {/* Other Routes */}
            <Route path='esiact' element={<ESIAct />} />
            <Route path='labourwelfareact' element={<LabourWelfareAct />} />
            <Route path='minimumwagesact' element={<MinimumWagesAct />} />
            <Route path='contractlabouract' element={<ContractLabourAct />} />
            <Route path='manpowerservices' element={<ManpowerServices />} />
        </Routes>
    )
}

export default Navigator