import React from 'react'
import '../css/about.css'
import Features from '../components/homeComponents/features/Features'
import HeaderTitle from '../components/headerTitle/HeaderTitle'
import aboutImage from '../assets/images/homepic.png'
import Growing from '../components/homeComponents/growing/Growing'
import { Col, Container, Row } from 'react-bootstrap'
import OurClients from '../components/homeComponents/ourClients/OurClients'

const About = () => {
  return (
    <div>
      <HeaderTitle title={'About'} />
      {aboutContainer()}
      <Features />
      <Growing />
      <OurClients />
    </div>
  )



  function aboutContainer() {
    return (
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div className='about-image-container' data-aos='fade-right'>
              <img src={aboutImage} className='about-image' />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className='about-content' data-aos='fade-left'>
              <h3>About <span>Manpower Company </span></h3>
              <h2>A Leading Manpower & Payroll
                <span> Outsourcing & Management Company</span>
              </h2>
              <p>
                A Leading Manpower & Payroll Outsourcing Management firm has achieved remarkable milestones with a commendable track record, boasting over 100+ satisfied clients. Each client benefits from our unparalleled services, making us the most efficient ESI, PF, Factory Act, and Payroll Management consultant in Delhi & NCR for the past 10+ years. Additionally, Manpower Company has revolutionized the management of statutory records under various labor statutes, including the Employees Provident Fund & Miscellaneous Provision Acts, 1952, E.S.I Act, Factories Act, 1948, Payment of Wages under Minimum Wages Act, Bonus Act, Delhi Labour Welfare Fund Rules 1997, and various other labor laws. Our firm possesses extensive expertise in outsourcing the maintenance of the aforementioned statutory records, including applications, follow-up measures, and eventual maintenance, addressing all associated challenges effectively.
              </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div data-aos='fade-up'>
              <h5>
                Why Choose us:
              </h5>
              <ul className='about_li'>
                <li>
                  <p><b>Extensive Experience:</b> With over 10+ years in the industry, our depth of knowledge and experience ensures that we provide the highest quality of service.</p>
                </li>
                <li>
                  <p><b>Efficiency and Cost-Effectiveness: </b>Our solutions are designed to maximize efficiency and reduce costs, making your operations smoother and more economical.</p>
                </li>
                <li>
                  <p><b>Risk and Hassle-Free Management:</b> We handle the complexities of staffing and payroll so you don’t have to, freeing you to focus on core business activities.</p>
                </li>
                <li>
                  <p><b>Client-Centric Approach:</b> We are committed to understanding and meeting our clients’ specific needs, with a focus on building long-term relationships.</p>
                </li>
              </ul>
              <p>
                Manpower Company is not just a service provider, but a reliable partner committed to excellence and client satisfaction. When you choose us, you are selecting a leader dedicated to your success in India.
              </p>
              <br></br>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <div data-aos='fade-up'>
              <h5>
                Below mentioned factors have helped us in becoming the foremost choice of our clients:
              </h5>
              <ul className='about_li'>
                <li>Efficient and trustworthy services</li>
                <li>Team of skilled professionals with expertise and comprehensive knowledge in various fields</li>
                <li>Our proficiency in identifying and addressing problem areas</li>
                <li>Affordable solutions</li>
                <li>Prompt delivery</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container >
    )
  }

}

export default About